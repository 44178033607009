import './App.css';
import { Test } from './components/test';
import { useStore } from './store';

function App() {
  const tests = useStore(state => state.tests);
  const status = useStore(state => state.status);
  return (
    <div className="app">
      <h1>JS Performance</h1>
      <div className="note">
        You can execute small javascript code snippets to test their performance.
        The code will be executed multiple times to calculate the number of operations per second.
        The code with the highest number of operations per second will be considered the fastest.<br /><br />
        You can also write asynchronous code using async/await syntax.
        For example:
        <pre>
          {`const response = await fetch('https://randomuser.me/api/');
const data = await response.json();
console.log(data);`}
        </pre>
      </div>
      <div className='actions'>
        <button onClick={e => {
          e.preventDefault();
          useStore.getState().addTest();
        }} disabled={status === "running"}>+ Add Test</button>
        <button onClick={e => {
          e.preventDefault();
          useStore.getState().runAllTests();
        }} disabled={status === "running"}>Run All Tests</button>
      </div>
      <div className='testlist'>
        {tests.map((test, index) => {
          return <Test index={index} />
        })}
      </div>
    </div>
  );
}

export default App;
