import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';

export const useStore = create(persist(
    (set, get) => {
        function runTest(index) {
            return new Promise(resolve => {
                const test = get().tests[index];
                set(produce(state => {
                    state.tests[index].status = 'running';
                }));
                try {
                    const evalScript = `
                        const start = performance.now();
                        let runCount = 0;
                        async function runTest(){
                            try{
                                do{
                                    ${test.code}
                                    runCount++;
                                } while(performance.now() - start < 5000);
                                const end = performance.now();
                                postMessage({
                                    totalTimeTaken: end - start,
                                    runCount,
                                    timeTaken: (end - start) / runCount
                                });
                            }
                            catch(e){
                                console.log(e);
                                postMessage({
                                    error: e.message
                                });
                            }
                        }
                        runTest();
                    `;
                    const jsBlob = new Blob([evalScript], { type: 'application/javascript' });
                    const jsBlobUrl = URL.createObjectURL(jsBlob);
                    const worker = new Worker(jsBlobUrl);
                    worker.onmessage = function(e){
                        console.log(e.data);
                        if(e.data.error){
                            set(produce(state => {
                                state.tests[index].status = 'error';
                                state.tests[index].reason = e.data.error;
                            }));
                            resolve();
                        }
                        else {
                            set(produce(state => {
                                state.tests[index].timeTaken = e.data.timeTaken;
                                state.tests[index].runCount = e.data.runCount;
                                state.tests[index].totalTimeTaken = e.data.totalTimeTaken;
                                state.tests[index].opsPerSec = (e.data.runCount / e.data.totalTimeTaken) * 1000;
                                state.tests[index].status = 'done';
                            }));
                            resolve();
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            })
        }
        return {
            tests: [{
                id: crypto.randomUUID(),
                title: 'Untitled Test',
                code: '',
                timeTaken: 0
            }],
            status: 'idle',
            addTest: () => {
                set(produce(state => {
                    state.tests.push({
                        id: crypto.randomUUID(),
                        title: 'Untitled Test',
                        code: '',
                        timeTaken: 0
                    });
                    state.status = 'idle';
                    state.tests.forEach((test, index) => {
                        state.tests[index].speedOrder = -1;
                        state.tests[index].status = null;
                    });
                }));
            },
            removeTest: (index) => {
                set(produce(state => {
                    state.tests.splice(index, 1);
                    state.status = 'idle';
                    state.tests.forEach((test, index) => {
                        state.tests[index].speedOrder = -1;
                        state.tests[index].status = null;
                    });
                }));
            },
            updateTest: (index, data) => {
                set(produce(state => {
                    state.tests[index].title = data.title;
                    state.tests[index].code = data.code;
                    state.status = 'idle';
                    state.tests.forEach((test, index) => {
                        state.tests[index].speedOrder = -1;
                        state.tests[index].status = null;
                    });
                }));
            },
            runTest,
            runAllTests: async () => {
                set(produce(state => {
                    state.status = 'running';
                    state.tests.forEach((test, index) => {
                        state.tests[index].status = 'idle';
                        state.tests[index].speedOrder = -1;
                        state.tests[index].speedStatus = null;
                        state.tests[index].speedColor = null;
                    });
                }));
                for(let i = 0; i < get().tests.length; i++){
                    await runTest(i);
                }
                set(produce(state => {
                    state.status = 'done';
                    const orderedTests = [...get().tests].filter(state => state.status !== 'error').sort((a, b) => b.opsPerSec - a.opsPerSec);
                    if(orderedTests.length > 0){
                        for(let i = 0; i < get().tests.length; i++){
                            state.tests[i].speedOrder = orderedTests.findIndex(test => test.id === state.tests[i].id);
                            if(orderedTests.length === 1){
                                state.tests[i].speedStatus = 'Only Test';
                                state.tests[i].speedColor = 'green';
                            }
                            else if(state.tests[i].speedOrder === 0){
                                state.tests[i].speedStatus = 'Fastest';
                                state.tests[i].speedColor = 'green';
                            }
                            else if(state.tests[i].speedOrder === (orderedTests.length - 1)){
                                state.tests[i].speedStatus = `${Math.ceil((1 - (state.tests[i].opsPerSec / orderedTests[0].opsPerSec)) * 100)}% Slower`;
                                state.tests[i].speedColor = 'red';
                            }
                            else {
                                state.tests[i].speedStatus = `${Math.ceil((1 - (state.tests[i].opsPerSec / orderedTests[0].opsPerSec)) * 100)}% Slower`;
                                state.tests[i].speedColor = 'orange';
                            }
                        }
                    }
                }));
            }
        }
    },
    {
        name: 'js-performance',
        getStorage: createJSONStorage(),
        partialize: state => Object.fromEntries(
            Object.entries(state).filter(([key]) => !['status'].includes(key)),
        ),
        version: 1
    }
));