import styles from './style.module.css';
import ReactCodeMirror from '@uiw/react-codemirror';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { javascript } from '@codemirror/lang-javascript';
import { useStore } from '../../store';

export function Test({
    onChange,
    index
}){
    const test = useStore(state => state.tests[index]);
    const allStatus = useStore(state => state.status);
    return <div className={styles.test}>
        <div className={styles.title}>
            <input type='text' value={test.title} onChange={e => {
                useStore.getState().updateTest(index, {
                    title: e.target.value,
                    code: test.code
                });
            }} />
        </div>
        <div>
            <ReactCodeMirror theme={vscodeDark} basicSetup={{
                autocompletion: true,
            }} extensions={[javascript({ jsx: false })]} minHeight={'150px'} onChange={code => {
                useStore.getState().updateTest(index, {
                    title: test.title,
                    code
                });
            }} value={test.code} readOnly={allStatus === 'running'}/>
        </div>
        <div className={styles.result}>
            <div>
                {test.status === 'idle' && <span className={styles.primary}>Waiting...</span>}
                {test.status === 'running' && <span className={styles.primary}>Running...</span>}
                {test.status === 'done' && !test.speedStatus && <>
                    <span className={`${styles.primary}`}>Ops/sec: {Intl.NumberFormat().format((test.runCount / test.totalTimeTaken) * 1000).toFixed(3)}</span>
                </>}
                {test.status === 'error' && <span className={styles.red}>{test.reason}</span>}
                {test.status === 'done' && test.speedStatus && <>
                    {<span className={styles[test.speedColor || 'primary']}>Ops/sec: {Intl.NumberFormat().format(Number((test.runCount / test.totalTimeTaken) * 1000).toFixed(3))}</span>}
                    {<span className={styles[test.speedColor]}>{test.speedStatus}</span>}
                </>}
            </div>
            <div>
                {useStore.getState().tests.length > 1 && <button onClick={e => {
                    e.preventDefault();
                    useStore.getState().removeTest(index);
                }}>🗑</button>}
            </div>
        </div>
    </div>
}